import $ from 'jquery';

$(document).ready(function() {

    $('.matchHeight').matchHeight();
    $( ".dgwt-wcas-product-details-readmore" ).text( "Lire la suite" );
    $('.wc-pao-addon-wrap label');

    $(".wc-pao-addon-wrap label").each(function() {
        var text = $(this).html();
        text = text.replace(/[(+)]/g, '');
        $(this).html(text);
    });
	$('.count').each(function (index, el) {
		let launched = false;
		$(window).scroll(function() {
			if (launched) return;
			const top = $(window).scrollTop() + $(window).outerHeight(true);
			const countBottom = $(el).offset().top + $(el).outerHeight(true);
			if (top >= countBottom) {
				launched = true;
				$(el).prop('Counter', 0).animate({
					Counter: $(el).text()
				}, {
					duration: 6000,
					easing: 'swing',
					step: function (now) {
						$(el).text(Math.ceil(now));
					}
				});
			}
		});
	});

});